import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = ({ data }) => {
  const eyesClosed = data.images.edges.find(
    ({ node }) => node.name === "augen-zu-rechts"
  )
  const eyesOpen = data.images.edges.find(
    ({ node }) => node.name === "augen-auf-rechts-bubble"
  )

  return (
    <Layout>
      <SEO
        title="About Anja Schneck"
        description="Learn more about Anja's background and her UX process."
        image={eyesClosed.node.childImageSharp.fluid.src}
      />
      <div className="second-main-container">
        <h2>About me.</h2>
        <div className="about-me-container">
          <div className="about-wrapper">
            <p className="about-text">
              The interaction between humans and complex systems has always
              fascinated me. My technophily and my background in Applied
              Cultural Sciences lead to my passion to help humans understand and
              easily interact with all kind of systems by creating logical
              architectures and meaningful interfaces.
            </p>
            <p className="main-text"></p>

            <p className="about-text">
              Innovative products as well as smart digital strategies determine
              the future success of companies. As a UX designer I want to help
              companies to keep the focus on the users and thus create
              meaningful products and drive forward digital transformations.
            </p>
          </div>
          <div className="mini-image-container">
            <img
              src="../../hover-me-animation.gif"
              className="hover-me"
              alt="me_hovered"
            />
            <img
              src="../../about-image-animated_2.gif"
              className="about-animated"
              style={{ width: "100%" }}
              alt="me"
            />
            <div style={{ width: "100%" }}>
              <Img
                fluid={eyesClosed.node.childImageSharp.fluid}
                className="no-mobile state-1"
              />
              <Img
                fluid={eyesOpen.node.childImageSharp.fluid}
                className="no-mobile state-2"
                loading="eager"
              />
            </div>
          </div>
        </div>

        <h2>My process.</h2>
        <div className="section-w-quote">
          <div className="about-wrapper">
            <h3>1. Research &amp; Empathise</h3>
            <p className="about-text">
              Understand the major subject and its context(s) by doing extensive
              research, competitive analysis and benchmarking. Understand users
              and their problems/ goals/ requirements/ pain-points/ needs by
              doing interviews/ analyse context of use/ research on relevant
              channels/ do empathy excercises.
            </p>
            <h3>2. Concept &amp; Architecture</h3>
            <p className="about-text">
              Using the results from my research to develop the concept and the
              information architecture. Solid architecture and a smart concept
              are the cornerstones of a successful product.
            </p>
            <h3>3. Wireframe &amp; Prototype</h3>
            <p className="about-text">
              Rough sketches with pen and paper quickly show whether concept and
              architecture can work. Iterations are then translated into digital
              wireframes that can be converted into clickable prototypes and
              used for first testings and further iterations.
            </p>
            <h3>4. UI Design</h3>
            <p className="about-text">
              Colours, fonts and overall visual style are defined taking into
              account existing branding guidelines, user needs and preferences
              (and of course design principles{" "}
              <span role="img" aria-label="nerd-emoji">
                &#129299;
              </span>
              ).
            </p>
          </div>
          <div className="quote-container">
            <blockquote>
              A sytem can only be as intelligent as its designers.
            </blockquote>
          </div>
        </div>

        <h2>Skills &amp; Tools</h2>
        <div className="double-block-container">
          <div className="double-block">
            <h3>Tools</h3>
            <ul className="skills">
              <li>Adobe CC</li>
              <li>Sketch</li>
              <li>Abstract</li>
              <li>Marvel</li>
              <li>InVision</li>
              <li>Jira</li>
              <li>Confluence</li>
              <li>MIRO</li>
              <li>G Suite</li>
            </ul>
          </div>
          <div className="double-block">
            <h3>Skills</h3>
            <ul className="skills">
              <li>Information Architecture</li>
              <li>Prototyping</li>
              <li>Wireframing</li>
              <li>User Interface Design</li>
              <li>Usability Testing</li>
              <li>User Research</li>
              <li>Agile Methods (Scrum &amp; Kanban)</li>
              <li>Basics in CSS, HTML and a little JS</li>
            </ul>
          </div>
        </div>

        <h2>Interested?</h2>
        <div className="section-w-quote">
          <div className="about-wrapper">
            <p className="about-text">
              If you are looking for a hard working, reliable and curious person
              for your team, a person who always leaves her ego at home and
              focuses on what’s best for the overall goal, please drop me a
              line.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllFilesToPathAbout {
    images: allFile(filter: { relativePath: { regex: "/about/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

export default SecondPage
